export const rubros = [
		{			
			"nombre": "1. Normatividad",
			"icono": "Normatividad.png",
			"formatos": [
				{
					"nombre": "Marco Normativo",
					"xlsx": "f01",
					"html": "f01"
				}
			]
		}, 
		{			
			"nombre": "2. Estructura",
			"icono":"Estructura.png",
			"formatos": [
				{
					"nombre": "Estructura Orgánica",
					"xlsx": "f02_a",
					"html": "f02_a"
				},
				{
					"nombre": "Organigrama",
					"xlsx": "f02_b",
					"html": "f02_b"
				}
			]
		},
		{			
			"nombre": "3. Facultades",
			"icono":"Facultades.png",
			"formatos": [
				{
					"nombre": "Facultades",
					"xlsx": "f03",
					"html": "f03"
				}
			]
		},
		{			
			"nombre": "4. Programas Operativos Anuales",
			"icono":"ProgramasOperativosAnuales.png",
			"formatos": [
				{
					"nombre": "Metas y Objetivos",
					"xlsx": "f04",
					"html": "f04"
				}
			]			
		},
		{			
			"nombre": "5. Indicadores de Interés",
			"icono":"IndicadoresInteres.png",
			"formatos": [
				{
					"nombre": "Indicadores de Interés",
					"xlsx": "f05",
					"html": "f05"
				}
			]			
		},
		{			
			"nombre": "6. Matriz de Indicadores",
			"icono":"MatrizIndicadores.png",
			"formatos": [
				{
					"nombre": "Matriz de Indicadores",
					"xlsx": "f06",
					"html": "f06"
				}
			]			
		},
		{			
			"nombre": "7. Directorio",
			"icono":"Directorio.png",
			"formatos": [
				{
					"nombre": "Directorio",
					"xlsx": "f07",
					"html": "f07"
				}
			]			
		},
		{			
			"nombre": "8. Sueldos",
			"icono":"Sueldos.png",
			"formatos": [
				{
					"nombre": "Remuneraciones",
					"xlsx": "f8",
					"html": "f8"
				}
			],
			"url" : "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/8.%20Sueldos"
		},
		{			
			"nombre": "9. Viáticos",
			"icono":"Viaticos.png",
			"formatos": [
				{
					"nombre": "Viáticos",
					"xlsx": "f09",
					"html": "f09"
				}
			]	
		},
		{			
			"nombre": "10. Plazas",
			"icono":"Plazas.png","formatos": [
				{
					"nombre": "Total de Personal de Base y Confianza",
					"xlsx": "f10_a",
					"html": "f10_a"
				},
				{
					"nombre": "Total de las Plazas de Base y Confianza",
					"xlsx": "f10_b",
					"html": "f10_b"
				}
			],
			"url" : "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/10.%20Plazas"	
		},
		{			
			"nombre": "11. Honorarios",
			"icono":"Honorarios.png",
			"formatos": [
				{
					"nombre": "Personal de Honorarios",
					"xlsx": "f11",
					"html": "f11"
				}
			]	
		},
		{
			"nombre": "12. Declaraciones Públicas",
			"icono":"DeclaracionesPublicas.png",
			"formatos": [
				{
					"nombre": "Declaraciones Patrimoniales",
					"xlsx": "f12",
					"html": "f12"
				}
			],
			"url" : "https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/12.%20Declaraciones%20P%C3%BAblicas"	
		},
		{
			"nombre": "13. Datos de Contacto de la Unidad",
			"icono":"DatosContactoUnidad.png",
			"formatos": [
				{
					"nombre": "Domicilio de la Unidad de Transparencia",
					"xlsx": "f13",
					"html": "f13"
				}
			],
			"url" : "https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/13.%20Datos%20de%20Contacto%20de%20la%20Unidad"
		},
		{			
			"nombre": "14. Convocatorias",
			"icono":"Convocatorias.png",
			"formatos": [
				{
					"nombre": "Convocatorias a Concursos para Cargos Públicos",
					"xlsx": "f14",
					"html": "f14"
				}
			],
			"url" : "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/14.%20Convocatorias"
		},
		{			
			"nombre": "15. Programas Sociales",
			"icono":"ProgramasSociales.png",
			"formatos": [
				{
					"nombre": "Programas de Subsidio, Estimulo y Apoyo",
					"xlsx": "f15_a",
					"html": "f15_a"
				},
				{
					"nombre": "Padrón de beneficiarios",
					"xlsx": "f15_b",
					"html": "f15_b"
				}
			]
		},
		{			
			"nombre": "16. Relaciones Laborales",
			"icono":"RelacionesLaborales.png",
			"formatos": [
				{
					"nombre": "Condiciones Generales de Trabajo",
					"xlsx": "f16_a",
					"html": "f16_a"
				},
				{
					"nombre": "Recursos Públicos",
					"xlsx": "f16_b",
					"html": "f16_b"
				}
			],
			"url" : "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/16.%20Relaciones%20Laborales"
		},
		{			
			"nombre": "17. Información Curricular",
			"icono":"InformacionCurricular.png",
			"formatos": [
				{
					"nombre": "Información Curricular",
					"xlsx": "f17",
					"html": "f17"
				}
			]
		},
		{			
			"nombre": "18. Sanciones Administrativas",
			"icono":"SancionesAdministrativas.png",
			"formatos": [
				{
					"nombre": "Servidores Públicos Sancionados",
					"xlsx": "f18",
					"html": "f18"
				}
			],
			"url" : "https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/18.%20Sanciones%20Administrativas"
		},
		{			
			"nombre": "19. Servicios",
			"icono":"Servicios.png",
			"formatos": [
				{
					"nombre": "Servicios",
					"xlsx": "f19",
					"html": "f19"
				}
			]			
		},
		{			
			"nombre": "20. Trámites",
			"icono":"Tramites.png",
			"formatos": [
				{
					"nombre": "Trámites",
					"xlsx": "f20",
					"html": "f20"
				}
			]			
		},
		{			
			"nombre": "21. Presupuesto e Informes Trimestrales",
			"icono":"PresupuestoInformesTrimestrales.png",
			"formatos": [
				{
					"nombre": "Presupuesto Anual",
					"xlsx": "f21_a",
					"html": "f21_a"
				},
				{
					"nombre": "Gasto Trimestral",
					"xlsx": "f21_b",
					"html": "f21_b"
				},
				{
					"nombre": "Cuenta Pública",
					"xlsx": "f21_c",
					"html": "f21_c"
				}
			],
			//"url" : "https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Presupuesto_e_Informes_Semestrales.html"
			"url" : "https://hacienda.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/21.Presupuesto%20e%20Informes%20Trimestrales"			
		},
		{			
			"nombre": "22. Deuda Pública",
			"icono":"DeudaPublica.png",
			"formatos": [
				{
					"nombre": "Deuda Pública",
					"xlsx": "f22",
					"html": "f22"
				}
			],
			//"url" : "https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Deuda_Publica.html"
			"url" : "https://hacienda.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/22.Deuda%20P%C3%BAblica"
		},
		{			
			"nombre": "23. Comunicación Social y Publicidad",
			"icono":"ComunicacionSocialPublicidad.png",
			"formatos": [
				{
					"nombre": "Programa Anual de Comunicación Social",
					"xlsx": "f23_a",
					"html": "f23_a"
				},
				{
					"nombre": "Erogación de Recursos de Comunicación Social",
					"xlsx": "f23_b",
					"html": "f23_b"
				},
				{
					"nombre": "Tiempos Oficiales",
					"xlsx": "f23_c",
					"html": "f23_c",
					"xlsx_liga": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/RadioTVH/dir1/FC/a69_f23_c_RTH.xlsx",					
					"htm_liga": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/RadioTVH/dir1/FC/a69_f23_c_RTH.htm"
				}
			]
		},
		{			
			"nombre": "24. Resultados de Auditorías",
			"icono":"ResultadosAuditorias.png",
			"formatos": [
				{
					"nombre": "Auditorías",
					"xlsx": "f24",
					"html": "f24"
				}
			],
			"url" : "https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/24.%20Resultados%20de%20Auditor%C3%ADas"
		},
		{			
			"nombre": "25. Dictámines de Estados Financiero ",
			"icono":"DictamenesFinancieros.png",
			"formatos": [
				{
					"nombre": "Estados Financieros",
					"xlsx": "f25",
					"html": "f25"
				}
			],
			//"url" : "https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Dictamenes_de_Estados_Financieros.html"
			"url" : "https://hacienda.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/25.Dict%C3%A1menes%20de%20Estados%20Financieros"
		},
		{			
			"nombre": "26. Asignación de Recursos Públicos a Personas",
			"icono":"AsignacionRecursosPublicosPersonas.png",
			"formatos": [
				{
					"nombre": "Personas a Quienes se asignó Recursos Públcios",
					"xlsx": "f26",
					"html": "f26"
				}
			],
			"url" : "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/26.%20Asignaci%C3%B3n%20de%20Recursos%20P%C3%BAblicos%20a%20Personas"
		},
		{			
			"nombre": "27. Concesiones",
			"icono":"Concesiones.png",
			"formatos": [
				{
					"nombre": "Concesiones, Contratos, Convenios, Permisos, Licencias y Autorizaciones",
					"xlsx": "f27",
					"html": "f27"
				}
			]			
		},
		{		
			"nombre": "28. Licitaciones Públicas",
			"icono":"LicitacionesPublicas.png",
			"formatos": [
				{
					"nombre": "Resultados de procedimientos de adjudicación directa, licitación pública e invitación restringida",
					"xlsx": "f28",
					"html": "f28"
				},
				// {
				// 	"nombre": "Procedimientos de Licitación Pública e Invitación a Cuando Menos Tres Personas",
				// 	"xlsx": "f28_a",
				// 	"html": "f28_a"
				// },
				// {
				// 	"nombre": "Procedimientos de Adjudicación Directa",
				// 	"xlsx": "f28_b",
				// 	"html": "f28_b"
				// }
			]
		},
		{			
			"nombre": "29. Informes",
			"icono":"Informes.png",
			"formatos": [
				{
					"nombre": "Informes",
					"xlsx": "f29",
					"html": "f29"
				}
			],
			"url" : "https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/29.%20Informes"			
		},
		{			
			"nombre": "30. Estadísticas Generadas",
			"icono":"EstadisticasGenerales.png",
			"formatos": [
				{
					"nombre": "Estadísticas",
					"xlsx": "f30",
					"html": "f30"
				}
			]			
		},
		{			
			"nombre": "31. Informe de Avances y Documentos Financiero",
			"icono":"InformeAvancesDocumentosFinancieros.png",
			"formatos": [
				{
					"nombre": "Informe de Estados Financieros",
					"xlsx": "f31_a",
					"html": "f31_a"
				},
				{
					"nombre": "Informe Contable, Presupuestal y Programático",
					"xlsx": "f31_b",
					"html": "f31_b"
				}
			],
			//"url" : "https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Informe_de_Avances_y_Documentos_Financieros.html"
			"url" : "https://hacienda.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/31.Informe%20de%20Avances%20y%20Documentos%20Financieros"
		},
		{			
			"nombre": "32. Padrón de Proveedores y Contratistas",
			"icono":"PadronProveedoresContratistas.png",
			"formatos": [
				{
					"nombre": "Padrón de Proveedores y Contratistas",
					"xlsx": "f32",
					"html": "f32"
				}
			],
			"url" : "https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/32.%20Padr%C3%B3n%20de%20Proveedores%20y%20Contratistas"
		},
		{			
			"nombre": "33. Convenios con Sectores Social y Privado",
			"icono":"ConveniosSectoresSocialPrivado.png",
			"formatos": [
				{
					"nombre": "Convenios de Coordinación y Concertación",
					"xlsx": "f33",
					"html": "f33"
				}
			]			
		},
		{			
			"nombre": "34. Inventario de Bienes",
			"icono":"InventarioBienes.png",
			"formatos": [
				{
					"nombre": "Inventario de Bienes Muebles",
					"xlsx": "f34_a",
					"html": "f34_a"
				},
				{
					"nombre": "Alta de Bienes Muebles",
					"xlsx": "f34_b",
					"html": "f34_b"
				},
				{
					"nombre": "Bajas de Bienes Muebles",
					"xlsx": "f34_c",
					"html": "f34_c"
				},
				{
					"nombre": "Inventario de Bienes Inmuebles",
					"xlsx": "f34_d",
					"html": "f34_d"
				},
				{
					"nombre": "Alta de Bienes Inmuebles",
					"xlsx": "f34_e",
					"html": "f34_e"
				},
				{
					"nombre": "Bajas de Bienes Inmuebles",
					"xlsx": "f34_f",
					"html": "f34_f"
				},
				{
					"nombre": "Donaciones de Bienes Muebles e Inmuebles",
					"xlsx": "f34_g",
					"html": "f34_g"
				}

			],
			"url" : "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/34.%20Inventario%20de%20Bienes"			
		},
		{			
			"nombre": "35. Recomendaciones y Atención en Materia de Derechos Humanos",
			"icono":"RecomendacionesAtenciónMateriaDerechosHumanos.png",
			"formatos": [
				{
					"nombre": "Recomendaciones de Derechos Humanos Emitidas por la CNDH",
					"xlsx": "f35_a",
					"html": "f35_a"
				},
				{
					"nombre": "Recomendaciones de Derechos Humanos Casos Especiales",
					"xlsx": "f35_b",
					"html": "f35_b"
				},
				{
					"nombre": "Las Recomendaciones de Derechos Humanos Org. Int. CNDH",
					"xlsx": "f35_c",
					"html": "f35_c"
				},
			]			
		},
		{			
			"nombre": "36. Resoluciones y Laudos de Juicios",
			"icono":"ResolucionesLaudosJuicios.png",
			"formatos": [
				{
					"nombre": "Resoluciones y Laudos ",
					"xlsx": "f36",
					"html": "f36"
				}
			],
			"url" : "https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/36.%20Resoluciones%20y%20Laudos%20de%20Juicios"
		},
		{			
			"nombre": "37. Mecanismo de Participación Ciudadana",
			"icono":"MecanismosParticipacionCiudadana.png",
			"formatos": [
				{
					"nombre": "Mecanismos de Participación Ciudadana",
					"xlsx": "f37_a",
					"html": "f37_a"
				},
				{
					"nombre": "Resultados de los Mecanismos de Participación Ciudadana",
					"xlsx": "f37_b",
					"html": "f37_b"
				}
			]			
		},
		{			
			"nombre": "38. Programas Ofrecidos",
			"icono":"ProgramasOfrecidos.png",
			"formatos": [
				{
					"nombre": "Programas que Ofrecen",
					"xlsx": "f38_a",
					"html": "f38_a"
				},
				{
					"nombre": "Tramites a los Programas que Ofrecen",
					"xlsx": "f38_b",
					"html": "f38_b"
				}
			],
			"url" : "https://s-salud.hidalgo.gob.mx/Transparencia/RubrosSsh/DetalleRubroSsh/38.%20Programas%20Ofrecidos"
		},
		{			
			"nombre": "39. Actas y Resoluciones del Comité de Transparencia",
			"icono":"ActasResolucionesComiteTransparencia.png",
			"formatos": [
				{
					"nombre": "Informe de Sesiones",
					"xlsx": "f39_a",
					"html": "f39_a"
				},
				{
					"nombre": "Informe de Resoluciones",
					"xlsx": "f39_b",
					"html": "f39_b"
				},
				{
					"nombre": "Actas Integrantes del Comité",
					"xlsx": "f39_c",
					"html": "f39_c"
				},
				{
					"nombre": "Calendario de Sesiones del comité",
					"xlsx": "f39_d",
					"html": "f39_d"
				}
			],
			"url" : "https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/39.%20Actas%20y%20Resoluciones%20del%20Comit%C3%A9%20de%20Transparencia"
		},
		{			
			"nombre": "40. Evaluacion y Encuesta a Programas Financiados con Recursos Públicos",
			"icono":"EvaluacionencuestasProgramas.png",
			"formatos": [
				{
					"nombre": "Evaluaciones",
					"xlsx": "f40_a",
					"html": "f40_a"
				},
				{
					"nombre": "Encuestas",
					"xlsx": "f40_b",
					"html": "f40_b"
				}
			],	
			//"url" : "https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Evaluacion_y_Encuestas_a_Programas_Financiados_con_Recursos_Publicos.html"
			"url" : "https://hacienda.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/40.Evaluacion%20y%20Encuestas%20a%20Programas%20Financiados%20con%20Recursos%20P%C3%BAblicos"
		},
		{			
			"nombre": "41. Estudios Pagados con Recursos Públcos",
			"icono":"EstudiosPagadosRecursosPublicos.png",
			"formatos": [
				{
					"nombre": "Estudios Pagados con Recursos Públicos ",
					"xlsx": "f41",
					"html": "f41"
				}
			]			
		},
		{			
			"nombre": "42. Listados Jubilados y Pensionados",
			"icono":"ListadoJubiladosPencionados.png",
			"formatos": [
				{
					"nombre": "Jubilados y Pensionados Leyenda",
					"xlsx": "f42_a",
					"html": "f42_a"
				},
				{
					"nombre": "Jubilados y Pensionados Listado",
					"xlsx": "f42_b",
					"html": "f42_b"
				}
			],	
			"url" : "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/42.%20Listados%20Jubilados%20y%20Pensionados"
		},
		{			
			"nombre": "43. Ingresos Recibidos por Cualquier Concepto",
			"icono":"IngresosRecibidosCualquierConcepto.png",
			"formatos": [
				{
					"nombre": "Ingresos Recibidos",
					"xlsx": "f43_a",
					"html": "f43_a"
				},
				{
					"nombre": "Responsables de Recibir los Ingresos",
					"xlsx": "f43_b",
					"html": "f43_b"
				}
			],	
			//"url" : "https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Ingresos_Recibidos_por_Cualquier_Concepto.html"
			"url" : "https://hacienda.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/43.Ingresos%20Recibidos%20por%20Cualquier%20Concepto"
		},
		{			
			"nombre": "44. Donaciones en dinero y en especie realizadas",
			"icono":"DonacionesHechasTerceros.png",
			"formatos": [
				{
					"nombre": "Donaciones en dinero y en especie realizadas",
					"xlsx": "f44",
					"html": "f44"
				},
				// {
				// 	"nombre": "Donaciones Hechas a Terceros en Especie",
				// 	"xlsx": "f44_b",
				// 	"html": "f44_b"
				// }
			]			
		},
		{			
			"nombre": "45. Catálogo Documental y Guia de Archivos",
			"icono":"Viaticos.png",
			"formatos": [
				{
					"nombre": "Inventarios Documentales",
					"xlsx": "f45_a",
					"html": "f45_a"
				},
				{
					"nombre": "Expedientes Clasificados como Reservados",
					"xlsx": "f45_b",
					"html": "f45_b",					
					"xlsx_liga": "https://s-contraloria.hidalgo.gob.mx/Transparencia/xlsx/a69_f45b_SC.xlsx",
					"htm_liga": "https://s-contraloria.hidalgo.gob.mx/Transparencia/htm/a69_f45b_SC.htm"
				},
				{
					"nombre": "Catálogo de Disposición y Guía de Archivos",
					"xlsx": "f45_c",
					"html": "f45_c"
				}
			]			
		},
		{			
			"nombre": "46. Actas de Sesiones, Opiniones y Recomendaciones de Consejos Consultivos",
			"icono":"CatalogoDocumentalGuiaArchivos.png",
			"formatos": [
				{
					"nombre": "Actas del Consejo Consultivo Ciudadano",
					"xlsx": "f46a",
					"html": "f46a"
				},
				{
					"nombre": "Opiniones del Consejo Consultivo Ciudadano",
					"xlsx": "f46b",
					"html": "f46b"
				}
			]			
		},
		{			
			"nombre": "47. Solicitud de Intervecion de  Comunicaciones",
			"icono":"SolicitudIntervencionComunicaciones.png",
			"formatos": [
				{
					"nombre": "Intervenciones de comunicaciones",
					"xlsx": "f47_a",
					"html": "f47_a"
				},
				{
					"nombre": "Localización Geográfica",
					"xlsx": "f47_b",
					"html": "f47_b"
				},
				{
					"nombre": "Mensaje de Solicitudes a empresas para la intervención de comunicaciones privadas",
					"xlsx": "f47_c",
					"html": "f47_c"
				}
			],
			"url" : "https://procuraduria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/Solicitud%20de%20Intervecion%20de%20%20Comunicaciones"
		},
		{			
			"nombre": "48. Información de Utilidad o Relevante",
			"icono":"InformacionUtilidadRelevante.png",
			"formatos": [
				{
					"nombre": "Interés Público",
					"xlsx": "f48_a",
					"html": "f48_a"
				},
				{
					"nombre": "Preguntas Frecuentes",
					"xlsx": "f48_b",
					"html": "f48_b"
				},
				{
					"nombre": "Transparencia Proactiva",
					"xlsx": "f48_c",
					"html": "f48_c"
				}
			]			
		},
]