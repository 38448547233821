import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './paaas.css';

const Paaas = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container paaas-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='paaas_title'>
                    <h1>Programa Anual de Adquisiciones, Arrendamientos y Servicios</h1>
                </div>
            </div>
        </div>

       <div className='row'>

            <div class="accordion" id="accordionExample2025">
                <div class="shadow mt-4 card-item-licitacion">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOnePaaas2025"><button class="accordion-button collapsed arccordion-gris" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnePaaas2025" aria-expanded="false" aria-controls="collapseOnePaaas2025">PAAAS 2025</button></h2>
                        <div id="collapseOnePaaas2025" class="accordion-collapse collapse" aria-labelledby="headingOnePaaas2025" data-bs-parent="#accordionExample2025">
                            <div class="accordion-body">
                                <ul class="list-group">
                                    <li class="list-group-item">Programa Anual de Adquisiciones, Arrendamientos y Servicios | 2025<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/PAAAS/PAAAS2025.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary-dos-pdf">.pdf</i></a></li>  
                                </ul>
                            </div>
                        </div>
                    </div>           
                </div>
            </div>
            <div class="accordion" id="accordionExample2024">
                <div class="shadow mt-4 card-item-licitacion">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOnePaaas2024"><button class="accordion-button collapsed arccordion-gris" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnePaaas2024" aria-expanded="false" aria-controls="collapseOnePaaas2024">PAAAS 2024</button></h2>
                        <div id="collapseOnePaaas2024" class="accordion-collapse collapse" aria-labelledby="headingOnePaaas2024" data-bs-parent="#accordionExample2024">
                            <div class="accordion-body">
                                <ul class="list-group">
                                    <li class="list-group-item">Programa Anual de Adquisiciones, Arrendamientos y Servicios | 2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/PAAAS/PAAAS2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary-dos-pdf">.pdf</i></a></li>  
                                </ul>
                            </div>
                        </div>
                    </div>           
                </div>
            </div>
            <div class="accordion" id="accordionExample2023">
                <div class="shadow mt-4 card-item-licitacion">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOnePaaas2023"><button class="accordion-button collapsed arccordion-gris" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnePaaas2023" aria-expanded="false" aria-controls="collapseOnePaaas2023">PAAAS 2023</button></h2>
                        <div id="collapseOnePaaas2023" class="accordion-collapse collapse" aria-labelledby="headingOnePaaas2023" data-bs-parent="#accordionExample2023">
                            <div class="accordion-body">
                                <ul class="list-group">
                                    <li class="list-group-item">Programa Anual de Adquisiciones, Arrendamientos y Servicios | 2023<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/PAAAS/PAAAS2023.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary-dos-pdf">.pdf</i></a></li>  
                                </ul>
                            </div>
                        </div>
                    </div>           
                </div>
            </div>
            <div class="accordion" id="accordionExample2022">
                <div class="shadow mt-4 card-item-licitacion">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOnePaaas2022"><button class="accordion-button collapsed arccordion-gris" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnePaaas2022" aria-expanded="false" aria-controls="collapseOnePaaas2022">PAAAS 2022</button></h2>
                        <div id="collapseOnePaaas2022" class="accordion-collapse collapse" aria-labelledby="headingOnePaaas2022" data-bs-parent="#accordionExample2022">
                            <div class="accordion-body">
                                <ul class="list-group">
                                    <li class="list-group-item">Programa Anual de Adquisiciones, Arrendamientos y Servicios | 2022<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/SCULTURA/dir1/PAAAS/PAAAS2022.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary-dos-pdf">.pdf</i></a></li>  
                                </ul>
                            </div>
                        </div>
                    </div>           
                </div>
            </div>

        </div>

    </div>
  )
}

export default Paaas